import { GenericCardCarousel as GenericCardCarouselComponent } from 'components/GenericCardCarousel';
import type {
  GenericPage,
  Product,
  GenericCardCarouseComponent as TGenericCardCarouseComponent,
} from 'server/repository/hygraph/generated/graphqlTypes';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

type TAlgoliaItems = GenericPage | Product;

interface IGenericCardCarousel {
  templateProps: TGenericCardCarouseComponent & {
    readMoreText: DictionaryItem;
  };
}

export const GenericCardCarousel = (
  props: IGenericCardCarousel &
    ISharedTemplateProps & {
      algoliaItems?: TAlgoliaItems[];
    },
) => {
  const {
    algoliaItems,
    templateProps: {
      articles,
      products,
      cardVariation,
      extension,
      dictionaryItem,
      heading,
      subHeading,
      pointCtaToArticlesList,
      readMoreText,
    },
    difficultyCopy,
    templateIndex,
    primarySubBrandColor,
  } = props;
  return (
    <GenericCardCarouselComponent
      algoliaItems={algoliaItems}
      articles={articles}
      products={products}
      cardVariation={cardVariation}
      extension={extension}
      dictionaryItem={dictionaryItem}
      heading={heading}
      subHeading={subHeading}
      pointCtaToArticlesList={pointCtaToArticlesList}
      difficultyCopy={difficultyCopy}
      templateIndex={templateIndex}
      primarySubBrandColor={primarySubBrandColor}
      readMoreText={readMoreText}
    />
  );
};
