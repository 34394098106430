import { CardCarousel } from 'components/CardCarousel';
import { mapCMSProductToCarousel } from './helpers/mapProductToCarousel';
import { useEffect, useState } from 'react';
import {
  ALGOLIA_SEARCH_INDEX_MAP,
  buildSearchLink,
} from 'server/repository/algolia/builder/Builder';

import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { CardVariation } from '../Cards/types';
import { TrackingService } from 'service/tracking/trackingService';
import { DictionaryService } from 'service/dictionary/dictionaryService';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import { StringService } from 'service/string/stringService';
import type { PathSegment } from 'service/localization/getPathTranslations';

interface IGenericCardCarousel {
  articles?: any[];
  products?: any[];
  cardVariation?: CardVariation | null;
  extension?: any;
  heading?: string | null;
  subHeading?: string | null;
  pointCtaToArticlesList?: boolean | null;
  algoliaItems?: any[];
  dictionaryItem?: DictionaryItem[];
  readMoreText?: DictionaryItem;
}

let cardCarouselIndex = 0;

export const GenericCardCarousel = ({
  algoliaItems,
  articles = [],
  products = [],
  cardVariation,
  extension,
  dictionaryItem,
  heading,
  subHeading,
  pointCtaToArticlesList,
  difficultyCopy,
  templateIndex,
  primarySubBrandColor,
  readMoreText,
}: IGenericCardCarousel & ISharedTemplateProps) => {
  const { pathTranslations } = useGlobalContext();
  cardCarouselIndex += 1;
  const data = algoliaItems
    ? algoliaItems
        /**
         * there are a lot of properties missing on templateProps?.products, but are necessary on type GenericPage[]
         * hence you cannot simply concat both arrays...
         * as a quickfix we cast it to GenericPage but this needs to be refactored...
         */
        .concat(articles)
        .concat(products)
    : articles.concat(products);

  const [searchQuery, setSearchQuery] = useState<string>();
  // search from extension
  useEffect(() => {
    const { variation, extensionKey } =
      ALGOLIA_SEARCH_INDEX_MAP[(cardVariation as CardVariation) || 'recipe'];

    const refinementList = (extension as any)?.[extensionKey]?.refinementList || {};
    setSearchQuery(buildSearchLink(refinementList, variation));
  }, []);

  const difficulty = {
    0: DictionaryService.getCopy('easy', dictionaryItem),
    1: DictionaryService.getCopy('intermediate', dictionaryItem),
    2: DictionaryService.getCopy('tough', dictionaryItem),
  };
  const [sliderSettings, setSliderSettings] = useState<{ [name: string]: any }>({
    slidesToShow: 3.5,
    slidesToScroll: 1,
    adaptiveHeight: false,
    infinite: true,
    arrows: true,
    dots: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
    ],
  });

  // Transform the Card Data.
  let linkUrl = '';

  const cardsData = data.slice(0, 30).map((item: any) => {
    if (item.__typename === 'Product') {
      return mapCMSProductToCarousel(
        item,
        DictionaryService.getCopy('learn_more', dictionaryItem),
        pathTranslations,
      );
    }
    if (item.__typename === 'GenericPage') {
      if (item?.type === 'Generic') {
        linkUrl = `${pathTranslations.genericPage}${item?.slug ?? ''}`;
      } else if (item?.type) {
        // this should be able to handle About | Product Promotion
        // | Services | Support | inspiration pages
        linkUrl = `${
          pathTranslations[StringService.lowerCaseFirstLetter(item?.type) as PathSegment]
        }${item?.subPath ? StringService.removeLeadingSlash(item?.subPath) : ''}${item?.slug}`;
      }
      const images = {
        desktop: item?.metaData?.images?.[0]?.url,
        desktopFallback: item?.metaData?.images?.[0]?.url,
        mobile: item?.metaData?.images?.[0]?.url,
        mobileFallback: item?.metaData?.images?.[0]?.url,
      };
      const findIntroTextTitle = (components: any) =>
        components?.find((component: any) => component?.__typename === 'IntroText')?.title;
      return {
        ...item,
        slug: linkUrl,
        images,
        cardTitle: findIntroTextTitle(item?.pageComponents),
      };
    }
    return item;
  });

  useEffect(() => {
    let slidesToShow = 3.5;

    if (cardsData.length < 4) {
      slidesToShow = cardsData.length - 0.01; // .99 for proper slider rendering
    }
    setSliderSettings({
      ...sliderSettings,
      slidesToShow,
      lazyLoad: 'ondemand' as any,
    });
  }, []);

  /**
   * Used to fire the teaser event for recipe and product cards in card carousel
   */
  const clickTeaserCardCarouselContainer = (cardData: any, position?: number) => {
    if (cardVariation === 'product') {
      TrackingService.productTeaserCardClick(
        cardData?.title || 'na',
        position || 0,
        heading || 'na',
        templateIndex ?? 0 + 1,
        'carousel',
      );
    } else {
      TrackingService.clickTeaserSeeRecipe({
        teaserName: cardData?.title,
        containerTitle: heading,
        containerPosition: templateIndex ?? 0 + 1,
        containerType: 'carousel',
        teaserPosition: position || 0,
        teaserRecipeId: cardData?.id,
        teaserRecipeTitle: cardData?.title,
        teaserRecipeDifficulty: difficultyCopy?.[cardData?.information?.difficulty] || 'na',
        teaserRecipeRating: cardData?.information?.rating,
        teaserRecipeDuration: cardData?.information?.totalTime,
      });
    }
  };

  /**
   * Used to fire the teaser event for recipe cards in card carousel
   */
  const clickTeaserRecipeListContainer = () => {
    TrackingService.clickTeaserRecipeList({
      containerTitle: heading,
      containerPosition: templateIndex ?? 0 + 1,
      containerType: 'carousel',
    });
  };

  return (
    <CardCarousel
      carouselData={{
        cardVariation: cardVariation || 'recipe',
        id: 'GenericCardCarousel',
        key: null,
        dictionaryItem,
        heading: heading || '',
        subHeading: subHeading || '',
        readMoreText,
      }}
      cardData={cardsData}
      copyDictionary={difficulty}
      settings={sliderSettings}
      showInfo
      searchLink={`${pathTranslations.search}?${
        pointCtaToArticlesList ? 'category=articles' : `${searchQuery}`
      }`}
      ctaColor={primarySubBrandColor}
      trackingEvents={{
        clickCard: clickTeaserCardCarouselContainer,
        clickCTA: clickTeaserRecipeListContainer,
      }}
      isOdd={!!(cardCarouselIndex % 2 === 0)}
    />
  );
};
