import type { Product } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ProductGridProduct } from 'types/Product';
import type { PathTranslations } from 'service/localization/getPathTranslations';

export type ProductCarousel = Product & {
  productImage?: {
    url: string;
    desktop?: string;
    mobile?: string;
  };
};

export const mapCMSProductToCarousel = (
  cmsProduct: ProductCarousel,
  linkText: string = 'Learn more',
  pathTranslations: PathTranslations,
): ProductGridProduct => {
  const mappedProduct: ProductGridProduct = {
    id: cmsProduct.id,
    category: '',
    subCategory: '',
    slug: cmsProduct?.slug || cmsProduct.id,
    title: cmsProduct.productTitle,
    productDietList: cmsProduct.productDietList,
    fillingQuantity: cmsProduct.fillingQuantity || '',
    metaKeywords: cmsProduct.metaKeywords || 'na',
    link: {
      url: `${pathTranslations.product}${cmsProduct.slug || ''}`,
      displayName: linkText,
    },
    subBrand: null,
    createdAt: cmsProduct.createdAt,
    updatedAt: cmsProduct.updatedAt,
  };

  if (cmsProduct?.productImage?.url) {
    mappedProduct.images = {
      desktop: cmsProduct?.productImage?.desktop,
      mobile: cmsProduct.productImage?.mobile,
      mimeType: String(cmsProduct.productImage?.mimeType),
    };
  }

  return mappedProduct;
};
